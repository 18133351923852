// 
// user.js
// Use this to write your custom JS
//


// const queryString = window.location.search;
// console.log(queryString);
// const urlParams = new URLSearchParams(queryString);
// const lang = urlParams.get('lang')
// console.log(lang);

// documents = document.getElementsByClassName(lang);

// for (var i = 0; i < documents.length; i++) {
//     //remove hidden class from each element
//     documents[i].classList.remove('hidden');
//     console.log(documents[i])
// }

// $(window).load(function(){        
//     $('#myModal').modal('show');
//      }); 

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDWmglMdjIad1HOTBbe9YcEWFCHS27BYqg",
  authDomain: "recordxwebsite.firebaseapp.com",
  projectId: "recordxwebsite",
  storageBucket: "recordxwebsite.appspot.com",
  messagingSenderId: "222181741983",
  appId: "1:222181741983:web:a3b9ae4afa156fa65b19aa",
  measurementId: "G-0NET3HXRH8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

console.log("analytics initialised")